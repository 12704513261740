import React from 'react';
import './App.css';
import image from './speed1.png'; // Make sure to place the image in the 'src' directory

function App() {
  return (
    <div className="App">
      <div className="content">
        <h1>Proof of Speed</h1>
        <img src={image} alt="Proof of Speed" />
        <p>Proof of Speed is a software solution initially targeting gamers who claim speed records for completing game levels. The system aims to provide verifiable proof of such achievements, ensuring accuracy and credibility.</p>
      </div>
    </div>
  );
}

export default App;